import { createRouter, createWebHistory, createWebHashHistory,} from "vue-router";
import _store from "../_store";
import {commonService} from '../_services/common.service'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import errorHandling from "../handleError/errorHandling";
import common from "../mixins/common.js"

const login = () => import(/* webpackChunkName: "login" */ "../views/login/login.vue");

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes : [
      // { path: "/", component: () => import("@/views/login/login.vue") },
      { path: "/", name: 'login', component: login,
      // beforeEnter: (to, from, next) => {
      //   // Perform initialization or async operations here if needed
      //   // Ensure to call next() when ready to proceed with navigation
      //   next();
      // },
      },
      {
        path: "/floatingBox",
        name: "floatingBox",
        component: () => import("../views/component/floating-pnl.vue"),
      },
      {
        path: "/authorizecdslmobile",
        name: "authorizecdslmobile",
        component: () => import("../views/home/holdings/edis-content.vue"),
      },
      
      {
        path: "/main",
        name: "main",
        component: () => import("../views/main_new.vue"),
        refirect: validSession() ? '/dashboard' : '/',
        children: [
          {
            path: "/dashboard",
            name: "dashboard",
            component: () => import("../views/home/dashboard/dashboard.vue"),
            meta: { auth: true, title:"Dashboard" },
          },
          {
            path: "/holdings",
            name: "holdings",
            component: () => import("../views/home/holdings/holdings.vue"),
            meta: { auth: true, title: "Holdings" }
          },
          {
            path: "/sectorlist",
            name: "sectorlist",
            component: () => import("../views/home/dashboard/sectorlist.vue"),
            meta: { auth: true, title: "Sectors" }
          },
          {
            path: "/analyticslist",
            name: "analyticslist",
            component: () => import("../views/home/dashboard/analyticslist.vue"),
            meta: { auth: true, title: "Analytics" },
          },
          {
            path: "/order",
            name: "order",
            // component: () => import("../views/home/order/order.vue"),
            component: () => import("../views/home/order/orders.vue"),
            meta: { auth: true, title: "Orders" }
          },
          {
            path: "/position",
            name: "position",
            component: () => import("../views/home/position/position.vue"),
            meta: { auth: true, title:"Positions"},
          },
          {
            path: "/apps",
            name: "apps",
            component: () => import("../views/home/apps/apps.vue"),
            meta: { auth: true, title: "Apps" },
          },
          {
            path: "/funds",
            name: "funds",
            component: () => import("../views/home/funds/funds.vue"),
            meta: { auth: true, title: "Funds" }
          },
          {
            path: "/util",
            name: "util",
            component: () => import("@/views/home/util.vue"),
            meta: { auth: true, title: "Utilities" },
          },
    
          {
            path: "/referal",
            name: "referal",
            component: () => import("../views/home/refer-earn.vue"),
            meta: { auth: true,  title: "Referal" },
          },
          {
            path: "/etflist",
            name: "etflist",
            component: () => import("../views/home/dashboard/etflist.vue"),
            meta: { auth: true, title: "ETF" },
          },
          {
            path: "/ipolist",
            name: "ipolist",
            component: () => import("../views/home/dashboard/ipolist.vue"),
            meta: { auth: true, title: "IPO" },
          },
          {
            path: "/settings",
            name: "settings",
            component: () => import("../views/home/settings/settings.vue"),
            meta: { auth: true,  title: "Settings" },
          },
          {
            path: "/chart",
            name: "chart",
            component: () => import("../views/home/chart/chart.vue"),
            meta: { auth: true, title: "Chart" },
          },
          {
            path: "/popoutChart",
            name: "popChart",
            component: () => import("../views/home/chart/popoutChart.vue"),
            meta: { auth: true }
          },
          {
            path: "/marketWatch",
            name: "marketWatch",
            component: () => import("../views/Watchlist/marketWatch.vue"),
            meta: { auth: true,  title: "Watchlist" },
          },
          {
            path: "/floatingBox",
            name: "floatingBox",
            component: () => import("../views/component/floating-pnl.vue"),
            meta: { auth: true },
          },
          {
            path: "/screener",
            name: "screener",
            component: () => import("../views/home/dashboard/screenerslist.vue"),
            meta: { auth: true, title: "Screener" },
          },
          {
            path: "/optionchain",
            name: "optionchain",
            component: () => import("../views/home/optionchain.vue"),
          },
          // {
          //   path: "/test",
          //   name: "test",
          //   component: () => import("../views/home/test.vue"),
          // },
          {
            path: "/indicestable",
            name: "indicestable",
            component: () => import("../views/home/dashboard/indices-table.vue"),
            meta: { auth: true, title:"Indices" },
            beforeEnter: (to, from, next) => {
              next();
            },
          },
          {
            path: "/mutualfundschart",
            name: "mutualfundschart",
            component: () => import("../views/home/dashboard/mutualfundschart.vue"),
            meta: { auth: true },
          },

        ],
      },
      { path: '/:catchAll(.*)', component: () => import("../components/notFound.vue"), },
    ]
  });

router.beforeEach(async (to, from, next) => {
  // active tab queries
  let quries =
    localStorage.getItem("tabQuries") &&
    localStorage.getItem("tabQuries") != undefined
      ? JSON.parse(localStorage.getItem("tabQuries"))
      : _store.state.defaultQueries;
  _store.commit("setQuries", { data: quries, action: "intial" }); 
  common.getDocumentTitle(to)
  // document title
  // document.title = to.path != '/' && to.meta && to.meta.title ? `${to.meta.title} / Pocket` : 'Pocket - Fast and nimble trading platform with Rs 0 brokerage for 30 days.'
  let stage = localStorage.getItem('stage') && localStorage.getItem('stage') != 'undefined' ? JSON.parse(localStorage.getItem('stage')) : ''
  let chartIndicators = localStorage.getItem('chartIndicators') && localStorage.getItem('chartIndicators') != "undefined" ? JSON.parse(localStorage.getItem("chartIndicators")) : ''
  if (chartIndicators) {
    _store.commit("chart/setChartIndicators", chartIndicators);
  }
  let ocInsExp = localStorage.getItem('ocInsExData') && localStorage.getItem('ocInsExData') != "undefined" ? JSON.parse(localStorage.getItem('ocInsExData')) : { exch: 'nse_fo', exp: '', ins: '' }
  if(ocInsExp){
    ocInsExp.where = 'initial'
    _store.commit("oc/setInsExp", ocInsExp)
  }
  _store.commit("login/setSSOQuery", '');
  let rgbObj = localStorage.getItem("rgbObj") && localStorage.getItem("rgbObj") != "undefined" ? JSON.parse(localStorage.getItem("rgbObj")) : ''
  if(rgbObj) {
    _store.commit("holdings/setRgbObj", rgbObj);
  }
  if(to.path != "/authorizecdslmobile") {
    if (
      Object.keys(to.query).length > 0 &&
      to.query.constructor === Object && to.query.hasOwnProperty('appcode') && 
      to.path == "/"
    ) {
      if (
        !validSession() &&
        from.path == "/" &&
        to.path == "/" 
      ) {

        errorHandling.unauthorizeHandle('', '', 'router')
        next();
      }
      _store.commit("login/setSSOQuery", to.query);
      _store.dispatch("login/getVendorDetails", { action: "initial" });
      
      next();
    } else {
      if (validSession() && to.path == "/") {
        _store.dispatch('pref/getUserPref', { from: 'router' }, { root: true })
        next();
      } else if (!validSession() && from.path == "/" && to.path != "/") {
        next("/");
      } else if (
        !validSession() &&
        from.path == "/" &&
        to.path == "/" 
      ) {
        errorHandling.unauthorizeHandle('', '', 'router')
        next();
      } else {
        next();
      }
    }
  } else if(to.path == "/authorizecdslmobile") {
    to.query.userId ? _store.commit("login/setCurrentUserId", to.query.userId) : "";
    if (to.query.isRevoke && to.query.session) {
      if (to.query.isRevoke.toLowerCase() == "true") {
        _store.commit("holdings/setAuthFlag", "revoke");
      } else {
        _store.commit("holdings/setAuthFlag", "auth");
      }
      _store.dispatch("login/setAccessToken", {token: to.query.session, type: 'init', from: 'beforeEach'});
    }
    next()
  }else{
    next()
  }
})


function validSession() {
  let userId = localStorage.getItem('UCC') && localStorage.getItem('UCC') != "undefined" ? JSON.parse(localStorage.getItem('UCC')) : ''
  let accessToken = localStorage.getItem('accessToken') && localStorage.getItem('accessToken') != "undefined" ?  JSON.parse(localStorage.getItem('accessToken')) : ''
  let mobile = localStorage.getItem("mobileNo") && localStorage.getItem("mobileNo") != "undefined" ? JSON.parse(localStorage.getItem("mobileNo")) : ''
  let mailId = localStorage.getItem("mailId") && localStorage.getItem("mailId") != "undefined" ? JSON.parse(localStorage.getItem("mailId")) : ''
  let pan = localStorage.getItem("pan") && localStorage.getItem("pan") != "undefined" ? JSON.parse(localStorage.getItem("pan")) : ''
  let role = localStorage.getItem("role") && localStorage.getItem("role") != "undefined" ? JSON.parse(localStorage.getItem("role")) : ''
  let isUpdateReq = localStorage.getItem("isUpdateReq") && localStorage.getItem("isUpdateReq") != "undefined" ? JSON.parse(localStorage.getItem("isUpdateReq")) : ''

  if(userId){
    _store.commit('login/setUCC',userId)
  }

  if (mobile) {
    _store.commit("login/setCurrentMobileNo", mobile);
  }

  if(accessToken) {
    _store.dispatch("login/setAccessToken", { token: accessToken, type: 'reload', from: 'validSession' });
  }

  if (mailId) {
    _store.commit("login/setCurrentEmailId", mailId);
  }
  
  if (pan) {
    _store.commit("login/setCurrentPanNumber", pan);
  }

  if (role) {
    _store.commit("login/setUserRole", role);
  }

  if(isUpdateReq) {
    _store.commit('login/setIsUpdateReq', isUpdateReq)
  }

  let valid
  (userId && accessToken )  ? valid = true : valid = false
  return valid
}

async function setDeviceId() {
  const fpPromise = FingerprintJS.load()
    await fpPromise.then(fp => fp.get())
        .then(result => {
          const visitorId = result.visitorId
          _store.commit('setDeviceId', visitorId)
        })
}

export default router;